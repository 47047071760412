<template>
  <v-form ref="form" v-model="valid" @submit="submit">
    <v-row justify="center" no-gutters>
      <v-col cols="12" class="text-center">
        <h2 class="custom-title-large">Xiii, esqueceu a senha?</h2>
        <p class="custom-normal-text text-green">
          Insira seu email de acesso e receba as instruções para redefinição de
          senha.</p
        >
      </v-col>
      <v-col cols="12" sm="5" md="7" class="text-center my-3">
        <v-text-field
          label="E-mail"
          v-model="email"
          :rules="emailRules"
          ref="email"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="text-center my-3">
        <v-btn
          class="custom-btn-green rounded-0 btn-large"
          :loading="loading"
          type="submit"
        >
          Redefinir Senha
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-center links">
      <router-link :to="'/login'" class="custom-text-link">
        Já é cliente? Clique aqui para entrar.
      </router-link>
    </div>
    <k-dialog v-model="dialog" :content="message" close-buttom> </k-dialog>
  </v-form>
</template>
<script>
import { email } from '@src/services/rules'
import kDialog from '@src/components/dialog/Simple.vue'
import { translator } from '@src/translator'

import { authMethods } from '@state/helpers'

export default {
  name: 'passord-reset',
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      message: null,
      email: null,
      emailRules: email,
    }
  },
  components: {
    kDialog,
  },
  methods: {
    ...authMethods,
    submit(ev) {
      ev.preventDefault()
      if (this.$refs.form.validate() && !this.loading) {
        this.loading = true
        this.passwordRequestResent({ email: this.email })
          .then((res) => {
            this.loading = false
            this.dialog = true
            if (res.message === 'Reset link sent.') {
              this.message = translator(
                'reset_link_sent',
                this.$refs.email.value
              )
            }
          })
          .catch((error) => {
            this.loading = false
            this.dialog = true
            if (error.response.data.message === 'Invalid user.') {
              this.message = translator('email_invalid')
            } else {
              this.message = translator('unknow_error_reset_passwowd')
            }
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
</style>
